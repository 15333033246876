@import "~@wac-ui-dashboard/wac_component_library/scss/variables.scss";
@import "~@wac-ui-dashboard/wac_component_library/scss/bootstrap.scss";
@import "~@wac-ui-dashboard/wac_component_library/scss/common.scss";
@import "swiper/swiper.min.css";
@import "./config";
:root,
[data-theme*="light"] {
  @include light-theme;
  --pro-range-bg: #{$pro-range-bg-light};
  --pro-image-gray-overlay: #{$pro-image-gray-overlay-light};
  --pro-invoice-label: #{$pro-invoice-label-light};
  --pro-input-focus-shadow: #{$pro-input-focus-shadow-light};
  --pro-input-option-hover-bg: #{$pro-input-option-hover-bg-light};
  --pro-input-option-active-bg: #{$pro-input-option-active-bg-light};
  --pro-editor-toolbar-bg: #{$pro-editor-toolbar-bg-light};
  --pro-shimmer-bg: #{$pro-shimmer-bg-light};
  --pro-shimmer-gradient-color1: #{$pro-shimmer-gradient-color1-light};
  --pro-shimmer-gradient-color2: #{$pro-shimmer-gradient-color2-light};
  --pro-shimmer-gradient-color3: #{$pro-shimmer-gradient-color3-light};
  --pro-shimmer-gradient-color4: #{$pro-shimmer-gradient-color4-light};
  --pro-offcanvas-bg-color: #{$pro-offcanvas-bg-light};
  --pro-pagination-active-color: #{$pro-pagination-active-color-light};
  --pro-auth-page-bg: #{$pro-auth-page-bg-light};
  --pro-blink-color-1: #{$pro-blink-color-1-light};
  --pro-blink-color-2: #{$pro-blink-color-2-light};
  --pro-blink-color-3: #{$pro-blink-color-3-light};
  --pro-blink-color-4: #{$pro-blink-color-4-light};
  --pro-blink-color-5: #{$pro-blink-color-5-light};
  --pro-blink-color-6: #{$pro-blink-color-6-light};
  --pro-dropdown-hover-bg: #{$pro-dropdown-hover-bg-light};
  --pro-table-link-color: #{$pro-table-link-color-light};
}
[data-theme*="dark"] {
  @include dark-theme;
  --pro-range-bg: #{$pro-range-bg-dark};
  --pro-image-gray-overlay: #{$pro-image-gray-overlay-dark};
  --pro-invoice-label: #{$pro-invoice-label-dark};
  --pro-input-focus-shadow: #{$pro-input-focus-shadow-dark};
  --pro-input-option-hover-bg: #{$pro-input-option-hover-bg-dark};
  --pro-input-option-active-bg: #{$pro-input-option-active-bg-dark};
  --pro-editor-toolbar-bg: #{$pro-editor-toolbar-bg-dark};
  --pro-shimmer-bg: #{$pro-shimmer-bg-dark};
  --pro-shimmer-gradient-color1: #{$pro-shimmer-gradient-color1-dark};
  --pro-shimmer-gradient-color2: #{$pro-shimmer-gradient-color2-dark};
  --pro-shimmer-gradient-color3: #{$pro-shimmer-gradient-color3-dark};
  --pro-shimmer-gradient-color4: #{$pro-shimmer-gradient-color4-dark};
  --pro-offcanvas-bg-color: #{$pro-offcanvas-bg-dark};
  --pro-pagination-active-color: #{$pro-pagination-active-color-dark};
  --pro-auth-page-bg: #{$pro-auth-page-bg-dark};
  --pro-blink-color-1: #{$pro-blink-color-1-dark};
  --pro-blink-color-2: #{$pro-blink-color-2-dark};
  --pro-blink-color-3: #{$pro-blink-color-3-dark};
  --pro-blink-color-4: #{$pro-blink-color-4-dark};
  --pro-blink-color-5: #{$pro-blink-color-5-dark};
  --pro-blink-color-6: #{$pro-blink-color-6-dark};
  --pro-dropdown-hover-bg: #{$pro-dropdown-hover-bg-dark};
  --pro-table-link-color: #{$pro-table-link-color-dark};
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  &::-webkit-scrollbar-track {
    background-color: #fafafa;
  }
  &::-webkit-scrollbar-thumb {
    // background-color: var(--pro-input-focus-shadow);
    background-color: #e2e2e2;
  }
  scrollbar-width: thin;
}

body {
  font-size: $font-size-normal;
  line-height: $primary-line-height;
  --toastify-toast-min-height: 56px;
  --toastify-z-index: 999;
}
.pro-toast-container {
  top: 60px !important;
  .pro-toast-progress {
    height: 2px;
  }
}
.wrapper {
  width: max-content;
  height: calc(100vh - 57px);
  position: sticky;
  top: 57px;
  > div {
    height: 100%;
  }
}
.wrapper-table {
  flex: 1;
  width: calc(100% - 260px);
  overflow: hidden;
  .pro-pagination {
    display: flex;
    justify-content: center;
  }
}
.wrapper-tables {
  width: 100%;
  overflow: hidden;
}

.pt-custom {
  padding-top: size(57);
}
.react-transform-component,
.react-transform-wrapper {
  width: 100% !important;
}

.scoll-top {
  position: fixed;
  bottom: 20px;
  right: 40px;
  width: 40px;
  height: 40px;
  border: 1px solid var(--pro-primary);
  background-color: var(--pro-light);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  z-index: 999;
  transition: 0.3s all ease-in-out;
  span {
    font-size: 25px !important;
    color: var(--pro-primary);
    transition: 0.3s all ease-in-out;
  }

  &:hover {
    background-color: var(--pro-primary);
    span {
      color: var(--pro-light) !important;
    }
  }
}

.pro-btn.pro-btn-outline-primary {
  --pro-button-color: var(--pro-primary);
  --pro-button-hover-color: var(--pro-light);
  border: 1px solid var(--pro-primary);
  --pro-button-hover-bg: var(--pro-primary);
  --pro-button-padding-y: #{size(8)};
  &:active {
    background-color: var(--pro-primary);
  }
}
.app-loading {
  height: 100%;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  &::after {
    width: 30px;
    height: 30px;
    border: 2px solid var(--pro-primary);
    border-radius: 50%;
    overflow: hidden;
    border-bottom-color: transparent;
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: rotation 0.6s infinite linear;
  }
  &.fill {
    position: fixed;
  }
}
@keyframes rotation {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(359deg);
  }
}

.pro-pnt {
  color: var(--pro-table-link-color);
  font-weight: 600; // no variables for fw-semibold
  cursor: pointer;
}

.shimmer {
  background: var(--pro-shimmer-bg);
  background-image: linear-gradient(
    to right,
    var(--pro-shimmer-gradient-color1) 0%,
    var(--pro-shimmer-gradient-color2) 20%,
    var(--pro-shimmer-gradient-color3) 40%,
    var(--pro-shimmer-gradient-color4) 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 100%;
  display: inline-block;
  position: relative;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-name: shimmer;
  -webkit-animation-name: shimmer;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  &.letter {
    width: 100%;
    height: 15px;
    &.sub {
      width: 90%;
    }
    &.para {
      height: 6px;
    }
  }
  &.img {
    padding-bottom: 100%;
    width: 100%;
  }
}

@-webkit-keyframes shimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.blink {
  background-color: var(--pro-blink-color-1);
  display: inline-block;
  position: relative;
  animation-name: blink;
  -webkit-animation-name: blink;
  animation-duration: var(--blink-duration);
  -webkit-animation-duration: var(--blink-duration);
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation-delay: var(--blink-delay);
  -webkit-animation-delay: var(--blink-delay);
  &.img {
    padding-bottom: 100%;
    width: 100%;
  }
}
@keyframes blink {
  0% {
    background-color: var(--pro-blink-color-1);
  }
  10% {
    background-color: var(--pro-blink-color-2);
  }
  20% {
    background-color: var(--pro-blink-color-3);
  }
  30% {
    background-color: var(--pro-blink-color-4);
  }
  40% {
    background-color: var9--pro-blink-color-5;
  }
  50% {
    background-color: var(--pro-blink-color-6);
  }
  60% {
    background-color: var9--pro-blink-color-5;
  }
  70% {
    background-color: var(--pro-blink-color-4);
  }
  80% {
    background-color: var(--pro-blink-color-3);
  }
  90% {
    background-color: var(--pro-blink-color-2);
  }
  100% {
    background-color: var(--pro-blink-color-1);
  }
}
.input-country-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  .input-main-wrap {
    position: relative;
    width: 100%;
    .code-wrap {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.34375rem 0.4375rem;
      font-size: 0.85rem;
      max-width: 84px;
      cursor: pointer;
      user-select: none;
      &::after {
        position: absolute;
        content: "";
        width: 1px;
        top: 5px;
        bottom: 5px;
        right: 0;
        background-color: var(--pro-border-color);
      }
      .country-select {
        .pro-input__control {
          background-color: inherit;
          .pro-input__single-value {
            color: var(--pro-dark);
          }
        }
        .pro-input__menu {
          background-color: var(--pro-light);
        }
      }
      .pro-input__control {
        border: 0;
        box-shadow: none !important;
      }
      .pro-input__value-container {
        padding: 0;
      }
      .pro-input__indicator-separator {
        display: none;
      }
      .pro-input__placeholder {
        font-size: 14px;
        color: var(--pro-dark);
      }
      .pro-input__indicator {
        padding: 4px;
      }
      .pro-input__menu-list {
        max-height: 250px;
      }
      .pro-input__menu {
        width: max-content;
        max-width: 300px;
      }
    }
    .pro-input {
      padding-left: 6rem;
    }
    &.verify-btn-wrap .pro-input {
      padding-right: 5rem;
    }
  }
  .error-text {
    font-size: 12px;
    line-height: $secondary-line-height;
    margin-top: 10px;
    color: var(--pro-danger);
  }
}

.verify-btn-wrap {
  position: relative;
  > .pro-btn {
    position: absolute;
    right: 4px;
    top: 4px;
    bottom: 4px;
  }
}

.offcanvas-footer-sticky-btns {
  position: sticky;
  bottom: -20px;
  padding: 16px 0;
  margin-bottom: -20px;
  background-color: var(--pro-offcanvas-bg-color);
  z-index: 8;
}

.pro-input__option {
  &:not(.pro-input__option--is-selected).pro-input__option--is-focused {
    background-color: var(--pro-input-option-hover-bg);
  }
  &:active {
    background-color: var(--pro-input-option-active-bg);
  }
  &.pro-input__option--is-selected {
    background-color: var(--pro-primary) !important;
    color: var(--pro-pagination-active-color) !important;
  }
}

.pro-input__control.css-13cymwt-control {
  cursor: pointer;
}

.hide-icon-input {
  padding-right: 40px;
}

.p-re {
  position: relative;
  .input-wrap {
    position: relative;
  }
  .hide-icon {
    right: 0;
  }
}

.hide-icon {
  position: absolute;
  right: 0;
  top: 24px;
  border: none;
  background-color: transparent;
  width: 41px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--pro-dark);
}

.modal .pro-btn-link .material-symbols-outlined {
  font-size: 1.5rem !important;
}

.dropdown .dropdown-item {
  transition: all 0.3s ease-in;
}

.pro-table th.checkbox-cell,
.pro-table td.checkbox-cell {
  min-width: 44px;
}
.no-border {
  .pro-input__control {
    border: none !important;
    cursor: pointer;
    background-color: inherit;
    box-shadow: none;
    .pro-input__single-value {
      color: var(--pro-dark);
    }
    &.pro-input__control--menu-is-open {
      box-shadow: 0 0 0 1px var(--pro-primary);
    }
    .pro-input__input-container {
      color: var(--pro-dark);
    }
  }
  .pro-input__menu {
    background-color: var(--pro-light);
  }
  .pro-input__indicators .pro-input__indicator-separator {
    display: none;
  }
}

.grey_bg {
  background-color: var(--pro-secondary);
}
.offcanvas.offcanvas-end {
  background-color: var(--pro-offcanvas-bg-color);
}

.pro-input:-webkit-autofill,
.pro-input:-webkit-autofill:hover {
  -webkit-text-fill-color: var(--pro-dark);
}
.input-date-picker {
  width: 100%;
  height: 40px;
  > div {
    width: 100%;
    .MuiInputBase-formControl {
      .MuiOutlinedInput-input {
        padding: 0;
        padding-left: 10px;
      }
      fieldset {
        border: none;
      }
    }
  }
}

.dropdown-item:hover,
 .dropdown-item:focus{
  color: var(--pro-dark);
  background-color: var(--pro-dropdown-hover-bg);
 }
.dashboard_no_padding {
  padding-top: 0 !important;
}

.scroll-table-wrap{
  display: flex;
  
  overflow-x: auto;
}
.pro-table-wrap{
  white-space: nowrap;
  overflow-x: auto;
  &::-webkit-scrollbar{
    height: 8px;
  }
  .pro-table{
    border-left: 0;
    height: 100%;
  }
  & + .pro-table-wrap{
    border-left: 1px solid var(--pro-outline-border);
  }
  &.fixed-table{
    max-width: size(550);
  }
  tr{
    th, td{
      &:nth-child(-n+3){
        z-index: 3;
        position: sticky;
      }
      &:first-child{
        left: 0;
        min-width: 44px;
        max-width: 44px;
        border-left: 1px solid var(--pro-outline-border);
      }
      &:nth-child(2){
        left: 44px;
        min-width: 275px;
        max-width: 275px;
      }
      &:nth-child(3){
        left: calc(44px + 275px);
        min-width: 275px;
        max-width: 275px;
      }
    }
    td:nth-child(-n+3){
      background-color: var(--pro-light);
    }
  }
  >div{
    position: sticky;
    left: 0;
  }
}

.cursor-pointer{
  cursor: pointer;
}
.pro-pagination-wrapper{
  z-index: 3;
}

.pro-no-point {
  pointer-events: none;
}
.search-filter-select{
  min-width: 200px;
}
.no-wrap{
  white-space: nowrap;
}
.pro-object-contain{
  object-fit: contain;
}
.dropdown-filter .pro-input .pro-input__single-value {
  padding: 0;
}
.pro-border-top{
  border-top: 1px solid var(--pro-outline-border);
}
.pro-border-bottom{
  border-bottom: 1px solid var(--pro-outline-border);
}
.pro-border{
  border: 1px solid var(--pro-outline-border);
}
.pro-text-label{
  color: var(--pro-input-text);
  font-weight: 500;
  font-size: 13px;
}
.pro-badge{
  font-weight: 500;
  svg{
    height: 12px;
    margin-right: 2px;
  }
  &.badge-success{
    --wac-badge-border-color : #C3E6CD;
    --wac-badge-bg : #C3E6CD;
    --wac-badge-color : #24663B;
  }
  &.badge-warning{
    --wac-badge-border-color : #FFF3D0;
    --wac-badge-bg : #FFF3D0;
    --wac-badge-color : #833C00;
  }
  &.badge-danger{
    --wac-badge-border-color : #FDD4CD;
    --wac-badge-bg : #FDD4CD;
    --wac-badge-color : #AE1800;
  }
}