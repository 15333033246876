.root {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #187A3D;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .root_inner {
    max-width: 423px;
    width: 60%;
  }

  .logoWrapper {
    width: 100%;
    max-width: 201.39px;
    margin-left: auto;
    margin-right: auto;

    img {
      object-fit: contain;
    }
  }

}


.auth {
  width: 100%;
  position: relative;

  :global {
    .pro-btn-link {
      margin: auto;
    }
  }
}

.circles {
  position: absolute;
  max-width: 1061px;
  width: 100%;
  left: 0;
  top: 0;
  pointer-events: none;
}

.img_man {
  position: absolute;
  max-width: 575px;
  width: 100%;
  left: 5%;
  bottom: 1%;

  >figure {
    padding-bottom: 98vh !important;

    img {
      object-fit: contain;
    }
  }
}

.error_text {
  color: var(--pro-danger);
  font-size: 12px;
  margin-top: 8px;
}

.privacy {
  left: 50%;
  transform: translateX(-50%);


}

.designed_by {
  right: 50px;

}

.privacy,
.designed_by {
  position: absolute;
  bottom: 25px;
  z-index: 2;
  display: flex;
  color: #ffffff;
  opacity: 0.7;
  column-gap: 5px;

  .link {
    color: white;

    text-decoration: underline;
    transition: opacity 0.3s;
    font-size: 14px;
    text-underline-offset: 4px;

    &:hover {
      opacity: 1;
    }
  }

}