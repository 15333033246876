.container{
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--pro-outline-border);
    border-radius: 8px;
    padding: 30px 35px;
    &.bg{
        background-color: var(--pro-secondary);
    }
}
.label{
    color: #828282;
}
.lg_spacer{
    margin-bottom: 35px;
}
.user_modal{
    .user_image{
        border-radius: 50%;
        position: relative;
        width: 100%;
        overflow: hidden;
        max-width: 52px;
    }
}