.root {
    width: 100%;
    position: relative;

    .item {
        ~.item {
            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px dashed var(--pro-border-color);
        }
    }
}

.root_item {
    p {
        opacity: 0.6;
        margin-top: 3px;
    }

    &+.root_item {
        border-top: 1px dashed var(--pro-outline-border);
        padding-top: 12px;
        margin-top: 12px;
    }
}