.root {
  display: flex;

  .img_wrap {
    max-width: 7.375rem;
    width: 100%;
    position: relative;
    figure {
      position: relative;
      overflow: hidden;
      padding-bottom: 100%;
      border-radius: 5px;
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
      }
    }
  }
  .list {
    margin-top: auto;
    li {
      position: relative;
      ~ li {
        padding-left: 7px;
        margin-left: 7px;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 2px;
          bottom: 2px;
          background-color: var(--pro-border-color);
          width: 1px;
        }
      }
    }
  }
}
.profile_icon {
  color: var(--pro-dark);
  font-size: 500px;
}

.editProfile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.2rem;
  height: 1.2rem;
  position: absolute;
  top: 3px;
  right: 3px;
  border-radius: 50%;
  background-color: var(--pro-light);
  color: var(--pro-dark);
  cursor: pointer;
  &:hover {
    box-shadow: 1px 1px 3px rgba($color: #000000, $alpha: 0.2);
  }
  > span {
    font-size: 12px !important;
  }
}
